<template>
  <div class="page-content" v-if="user">
    <page-breadcrumb
      title="User Detail"
      :items="breadcrumbItems"
    />
    <b-card class="mt-3">
      <!-- edit buttons -->
      <div class="d-flex justify-content-end">
        <!-- <b-button class="btn-icon" @click="editAble">
          <feather-icon :icon="!isEditing ? 'EditIcon' : 'XIcon'" />
        </b-button> -->
        <!-- <b-button v-if="isEditing" class="btn-icon ml-50" variant="primary" @click="updateUserEmail">
          <b-spinner v-if="isLoading" small class="mr-50" />
          <feather-icon v-else icon="CheckIcon"/>
        </b-button> -->
        <delete-button v-if="userData.role === 'admin'" class="btn-icon ml-50" variant="relief-danger" @delete="suspendUser"
          v-b-tooltip.hover="'suspend user'"
        >
          <b-spinner v-if="isLoading" small />
          <feather-icon v-else icon="SlashIcon" />
        </delete-button>
      </div>
      <!-- edit buttons end -->

      <b-media class="mt-1">
        <template #aside>
          <b-avatar
            rounded
            size="60"
            :src="user.avatar"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          >
            <feather-icon
              v-if="!user.email"
              icon="UserIcon"
              size="60"
            />
          </b-avatar>
        </template>
        <b-card-text class="d-flex">
          <div class="mr-3">
            <template>
            <b>Email: {{ user.email }}</b>
            </template>
            <!-- <validation-observer
              ref="updateEmailForm"
              v-slot="{ handleSubmit }"
            >
              <b-form @submit.stop.prevent="handleSubmit()">
                <validation-provider
                  name="email"
                  v-slot="validationContext"
                  :rules="{ required: true, email: true }"
                >
                  <b-form-input v-model="user.email" name="email" id="email" type="text" :state="getValidationState(validationContext)" />
                  <b-form-invalid-feedback id="milestone-id-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form>
            </validation-observer> -->
            <span class="mt-1" style="display: block">Name: {{ user.name }}</span>
          </div>
          <div class="d-flex flex-column">
            
          <b-popover v-if="resetCondition.message" target="popover-target-email" triggers="hover" placement="top">
            {{ resetCondition.message }}
          </b-popover>
          <b-popover v-if="resetCondition.message" target="popover-target-password" triggers="hover" placement="top">
            {{ resetCondition.message }}
          </b-popover>
            <div id="popover-target-email" class="mb-1">
              <b-button v-if="!isEdittingEmail" class="mb-1" size="sm" variant="success" :disabled="!resetCondition.is_allow" @click="isEdittingEmail=true">Reset Email</b-button>
              <div v-else class="d-flex align-items-center">
                <span class="mr-1" style="text-wrap: nowrap">New Email</span>
                <validation-observer
                  ref="updateEmailForm"
                  v-slot="{ handleSubmit }"
                  class="mr-1"
                >
                  <b-form @submit.stop.prevent="handleSubmit()">
                    <validation-provider
                      name="email"
                      v-slot="validationContext"
                      :rules="{ required: true, email: true }"
                    >
                      <b-form-input v-model="userEmail" name="email" id="email" type="text" :state="getValidationState(validationContext)" />
                      <b-form-invalid-feedback id="milestone-id-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form>
                </validation-observer>
                <b-button class="mr-1" variant="success" @click="updateUserEmail">Save</b-button>
                <b-button class="mr-1" variant="success" @click="isEdittingEmail= false">Cancel</b-button>
              </div>
            </div>  
            <div id="popover-target-password">
              <b-button v-if="!isEdittingPassword" variant="success" size="sm" :disabled="!resetCondition.is_allow" @click="isEdittingPassword=true">Reset Password</b-button>
              <div v-else class="d-flex align-items-center">
                <span class="mr-1" style="text-wrap: nowrap">New Password</span>
                <validation-observer
                  ref="updatePasswordForm"
                  v-slot="{ handleSubmit }"
                  class="mr-1"
                >
                  <b-form @submit.stop.prevent="handleSubmit()">
                    <validation-provider
                      name="password"
                      v-slot="validationContext"
                      :rules="{ required: true }"
                    >
                      <b-form-input v-model="userPassword" name="password" id="password" type="text" :state="getValidationState(validationContext)" />
                      <b-form-invalid-feedback id="milestone-id-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form>
                </validation-observer>
                <b-button class="mr-1" variant="success" @click="updateUserPassword">Save</b-button>
                <b-button class="mr-1" variant="success" @click="isEdittingPassword= false">Cancel</b-button>
              </div>
            </div>
          </div>
        </b-card-text>
      </b-media>
      <b-row>
        <b-col cols="6">
          <h5 class="mb-75 mt-2">Status: <b-badge :variant="userStatus.variant">{{ userStatus.text }}</b-badge></h5>
          <h5 class="mb-75 mt-2">Language: {{ user.language }}</h5>
          <h5 class="mb-75 mt-2">Country: {{ user.country }}</h5>
          <h5 class="mb-75 mt-2">Phone: {{ user.phone }}</h5>
          <h5 class="mb-75 mt-2">Verified Email: {{ user.is_email_verified ? 'Yes' : 'No' }}</h5>
        </b-col>
        <b-col cols="6">
          <h5 class="mb-75 mt-2">Username: {{ user.username }}</h5>
          <h5 class="mb-75 mt-2">DOB: {{ user.date_of_birth }}</h5>
          <h5 class="mb-75 mt-2">Sex: {{ sex_options[user.sex] }}</h5>
          <h5 class="mb-75 mt-2">Job: {{ job_options[user.job] }}</h5>
        </b-col>
      </b-row>
      
      <!-- login devices -->
      <div class="row">
        <div class="col-8">
          <div class="mt-2">
            <h5 class="mb-75">Device:</h5>
          </div>
        </div>
        <div class="col-2">
          <div class="mt-2">
            <h5 class="mb-75">First login at</h5>
          </div>
        </div>
        <div class="col-2">
          <div class="mt-2">
            <h5 class="mb-75">Login device</h5>
          </div>
        </div>
      </div>
      <div class="row" v-for="device in login_devices" :key="device._id">
        <div class="col-8">
          <div class="mt-1">
            {{ getLoginDevice(device) }}
          </div>
        </div>
        <div class="col-2">
          <div class="mt-1">
            {{ device ? device.createdAt : '' | local_time_string }}
          </div>
        </div>
        <div class="col-2">
          <div class="mt-1">
            {{ device.registration_id ? 'Active' : 'Inactive' }}
          </div>
        </div>
      </div>
      <!-- login devices end -->
    </b-card>
    <!-- <section class="my-3">
      <h2 class="h2">User learn record</h2>
      <loading-button variant="success" class="mb-1" icon="RefreshCcwIcon" :is-loading="is_recalculating"
        @click="getRecord"
      >Get record</loading-button>
      <user-record-table
        :display_results="user_learn_record"
        @changePage="getRecord"
      />
    </section> -->
    <action-buttons back-button />
    <b-card v-if="user.role === 2">
      <h2 class="h2">Teacher Info</h2>
      <div>
        <b-form-group label="About me" label-for="about_me">
          <b-form-input v-model="user.teacher_description.about_me" id="about_me" />
        </b-form-group>
        <b-form-group label="Education" label-for="education">
          <b-form-input v-model="user.teacher_description.education" id="education" />
        </b-form-group>
        <b-form-group label="Experience" label-for="experience">
          <b-form-input v-model="user.teacher_description.experience" id="experience" />
        </b-form-group>
        <b-button variant="success" style="float: right" @click="updateTeacherDescription">Update</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import service from "../service";
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import {STATUS} from './../constants.js';
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      user: null,
      user_products: null,
      login_devices: null,
      isUpdating: false,
      isDeleting: false,
      user_learn_record: null,
      is_recalculating: false,
      isEditing: false,
      isLoading: false,
      isShowEntryLink: false,
      entryTestWebLink: null,
      learnProgress: null,
      userPronunResult: null,
      userBreakingResult: null,
      lastAct: null,
      sex_options: {
        1: "Male",
        2: "Female",
        3: "Other"
      },
      job_options: {
        1: "Pupil",
        2: "Student",
        3: "Self-employed",
        4: "Office worker",
        5: "Retiree",
        6: "Other"
      },
      resetCondition: {
        is_allow: false,
        message: null
      },
      userEmail: null,
      userPassword: null,
      isEdittingEmail: false,
      isEdittingPassword: false,
    };
  },
  computed: {
    userId() {
      return this.$route.params.user_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "User List",
          to: { name: "user-list" },
        },
      ];
      items.push({ text: "User Detail", active: true });
      return items;
    },
    userStatus() {
      let status = STATUS.find(item => item.value === this.user.status);
      return status || { text: 'unknown' };
    },
    userData() {
      return this.$store.getters['auth/userData']
    },
  },
  async created() {
    this.get();
    this.getUserLearnProgress();
    this.getUserLastAct();
    this.resetEmailCondition();
  },
  methods: {
    async get() {
      let response = await service.get({ id: this.userId });
      if (response) {
        this.user = response.user;
        this.user_products = response.user_products;
        this.login_devices = response.login_devices;
        this.entryTestWebLink = `https://goga.ai/${this.user.language}/entry-test-report?email=${this.user.email}`;
        this.userEmail = this.user.email;
      }
    },
    async getRecord(){
      this.is_recalculating = true;
      let response = await service.getUserRecord({ user_id: this.userId});
      if (response){
        this.user_learn_record = response.user_summary
      }
      this.is_recalculating = false;
    },

    editAble() {
      this.isEditing = !this.isEditing
    },

    async updateUserEmail() {
      let validation = await this.$refs.updateEmailForm.validate();
      if (validation){
        this.isLoading = true
        const response = await service.updateUserEmail({ id: this.userId, email: this.userEmail, current_email: this.user.email })
        if (response) {
          this.$store.dispatch('pushSuccessNotify', { text: 'Successfully updated email!' })
          this.get();
          this.isEdittingEmail = false;
          this.userEmail = null;
        }
        this.isLoading = false
      }
    },

    async suspendUser() {
      this.isLoading = true
      const response = await service.suspend({ id: this.userId })
      if (response) {
        this.get()
      }
      this.isLoading = false
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    getLoginDevice(device) {
      if (!device || !device.info) {
        return ''
      }
      if (device.info[0] !== '{') {
        return device.info
      }
      let split = device.info.split("brand':'")[1]
      split = split.slice(0,split.length - 2).replace("','os':'", ', ')

      return split
    },
    async getUserLearnProgress(){
      let response = await service.getUserLearnProgress({user_id: this.userId });
      if (response){
        this.learnProgress = response;
      }
    },
    async getUserLastAct(){
      this.lastAct = await service.getUserLastAct({ id: this.userId });
    },
    async resetEmailCondition(){
      let response = await service.resetEmailCondition({id: this.userId});
      if (response){
        this.resetCondition.is_allow = response.is_allow;
        this.resetCondition.message = response.message;
      }
    },
    async updateUserPassword() {
      let validation = await this.$refs.updatePasswordForm.validate();
      if (validation){
        this.isLoading = true
        const response = await service.updateUserPassword({ email: this.userEmail, new_password: this.userPassword })
        if (response) {
          this.$store.dispatch('pushSuccessNotify', { text: 'Successfully updated password!' })
          this.get();
          this.isEdittingPassword = false;
          this.userPassword = null;
        }
        this.isLoading = false;
      }
    },
    async updateTeacherDescription(){
      const response = await service.updateTeacherDescription({ teacher_id: this.user._id, teacher_description: this.user.teacher_description })
      if (response.type === 'SUCCESS') {
        this.$store.dispatch('pushSuccessNotify', { text: 'Successfully updated!' })
      }
      else {
        this.$store.dispatch('pushErrorNotify', { text: 'Error!' })
      }
    }
  },
};
</script>
